html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-alt;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.editor-text {
	color: $color-text;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 10%);
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}

.title-lev2 {
	    position: relative;

	    @include font-size(20);
	    text-transform: uppercase;
	    font-weight: 800;
	   	color: $color-1;

	    margin: 0 0 40px 0;
	    padding: 0px 0 0 44px;
	}

.numero {
		position: relative;
		float: left;
		width: 32px;
		height: 32px;
		float: left;
		left: 0;
		top: -4px;
		background-color: $color-1;

		@include font-size(18);
		color: $white;
		text-align: center;

		padding: 5px 0 0 0;
		margin: 0 12px 0 0;
}

/* 
##            ###     ##    ##    #######   ##     ##  ########   
##           ## ##     ##  ##    ##     ##  ##     ##     ##      
##          ##   ##     ####     ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
##         #########     ##      ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
########   ##     ##     ##       #######    #######      ##      
*/

.header {
	background-color: $color-2;
}

.wrapper {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 50px 20px;
	clear: both;
	// overflow: hidden;

	@include mq($from: tablet) {
		width: 600px;
		max-width: none;
	}

	@include mq($from: desktop) {
		width: 1000px;
	}
}


.step-container {
	clear: both;
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	// padding: 50px 20px;
}

.step-slider {
	margin: 0;
	width: 400%;
	@include clearfix();
	transition: transform 0.3s ease-in-out;

	&.step-2 {
		transform: translate(-25%,0);
	}

	&.step-3 {
		transform: translate(-50%,0);
	}

	&.step-4 {
		transform: translate(-75%,0);
	}
}

.step-item {
	float: left;
	padding: 50px 20px;
	width: 25%;

	&:not(:first-child) {
		display: none;
	}
}

/* 

/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/

.content-header {
	position: relative;
	height: auto;
	text-align: center;

	.content-header-avis {
		color: $white;
		@include font-size(14);
		text-transform: uppercase;
		background-color: $color-3;
		padding: 20px;
		margin: 0 0 50px 0;

		@include mq($from: tablet) {
			@include font-size(16);	
		}
	}
}

.logo {

	display: inline-block;
	width: 260px;
	padding: 30px;
	top: 0px;

	@include mq($from: desktop) {
		position: relative;
		width: auto;
		left: 0px;
		top: 0px;
		transform: translateX(0%) translateY(0%);
	}
}

/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/


.progress-bar {
	position: relative;
	top:-50px;
	height: 10px;
	width: 100%;
	background-color:  $color-2;

	.col-etape {
		display: block;
		float: left;
		width: 25%;
		color: $color-3;
	}

	.etape {
		position: relative;
		padding: 20px 0 0 0;

		text-align: center;
		@include font-size(11);

		&:before {
			content: '|';
			position: absolute;
			top: 2px;
			left: 50%;
		}

		.active & {
			color: $color-2;
		}
	}

	.active {
		height: 10px;
		background-color: $color-1;
		color: $color-1;
		font-weight: 800;
	}
}

.contact-form {
	
	display: block;
	margin-top: 50px;

	h2 {
		@include font-size(20);

		text-transform: uppercase;
		font-weight: 800;
		margin-bottom: 30px;
		color: $color-1;
	}

	li {
		@include font-size(14);

		padding: 25px 0;
		line-height: 2.8em;
		width: 100%;
	}

	input, select, textarea {
		padding: 10px;
		height: 45px;
		border: 1px solid rgba(1,1,1,0.2);
		width: 100%;

		@include mq($from: desktop) {
			width: 250px;
			margin-left: 50px;
			float: right;
		}
	}

	button {
		@include font-size(20);

		background-color: $color-2;
		color: $white;
		border: none;
		text-transform: uppercase;
		height: 70px;
		width: 100%;
		text-transform: uppercase;
	    -webkit-transition: background-color .25s ease-in-out;
	    transition: background-color .25s ease-in-out;
	    border-radius: 0; 

		@include mq($from: tablet) {
			position: relative;
		}

		@include mq($from: desktop) {
			margin: 80px 0 0 0;
			width: 500px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	button:hover {
		
		background-color: #df1620;

	}


}

.no-margin {
	margin: 0 !important;
}


.error {
	border: 2px dashed red;
	background: rgba(red, 0.03);
	margin-bottom: 20px !important;
	margin: 10px !important;
	padding: 10px;
}

.error-msg {
	display: none;
	color: red;
	@include font-size(13);

	.error & {
		display: block;
	}
}

.success {
	background-color: rgb(46, 171, 97) !important;
}

.question {
	margin-bottom: 20px;
	border-bottom: 1px solid #bfbfbf;
}

.input {
	padding: 6px;
	height: 35px;
	border: 1px solid rgba(1,1,1,0.2);
	margin-left: 10px;
	// width: 100%;

	@include mq($from: desktop) {
		// width: 250px;
		// margin-left: 50px;
		// float: right;
	}

	&.input--complement {
		float: left;
		margin-left: 10px;
		margin-top: -7px;
		display: none;

	}
}

.btn-checkbox, .btn-radio {
	padding: 0 0 15px 0;
	// display: inline-block;
	@include clearfix();

	.btn-radio--input {
		margin: 3px 0px 0 0;
	    float: left;
	}

	label {
		position: relative;
		cursor: pointer;
	    display: block;
	    float: left;
	    margin-left: 5px;
	    // line-height: 30px;
	}
}

.btn-radio {
	display: block;
	@include mq($from: tablet) {
		display: inline-block;
		float: left;
		margin: 0 40px 0 0;
	}
}

.btn-select {
	select {
	    margin: 10px 0 0 0;
	}

	label {
		position: relative;
		cursor: pointer;
	}
}

.sous-question {
	padding: 0 0 20px 0;

	color: $color-1;
    font-size: 16px;
}

.bool-label {
	padding: 0 0 5px 0;
	font-weight: bold;
}

.indication {
	display: block;
	padding: 10px 0 10px 10px;

	font-weight: 800;
	color: $color-3;
	
	@include mq($from: tablet) {
		padding: 10px 0 0 10px;
	}

	@include mq($from: desktop) {
		padding: 10px 0 0 14px;
	}
}

.note {
	display: inline-block;
	margin: 0 0 0 10px;

	@include mq($from: tablet) {
		display: block;
		margin: 0;
	}
}

.sur-cinq {
	@include font-size(11);
}


.btn {
	display: inline-block;
	position: relative;	
	height: 48px;
	text-align: center;
	background-color: $color-3;
	padding: 15px;
	width: 49%;
	overflow: hidden;
	color: $white;
	@include font-size(16);
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: bold;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	&:after, &:before {
		position: absolute;
		content: '';
		height: 24px;
   		width: 20px;
		background: url('#{$image-path}arrow.svg') no-repeat;

	}

	&:hover {
		background-color: $color-1;
	}

	&.btn--inactive {
		border: 1px solid grey;
		color: grey;
		cursor: default;

		&:after, &:before {
			display: none;
		}

		&:hover {
			background-color: $color-3;
		}
	}

	&.next {
		float: right;
		
		&:after {			
			right: -50px;
			transition: right 0.2s ease-in-out;
		}

		&:before {
			display: none;
		}

		&:hover {
			&:after {
				right: 15px;
			}
		}
	}

	&.previous {
		float: left;

		&:after {
			display: none;
		}

		&:before {
			left: -50px;
			top: 10px;
			transform: rotate(180deg);
			transition: left 0.2s ease-in-out;
		}

		&:hover {
			&:before {
				left: 15px;
			}
		}
	}
}



.suggestions {
	width: 100%;
	height: 150px;
	margin: 20px 0 0;
}

.last-form {
	input {
		width: 100%;
	    height: 30px;
	    margin: 15px 0 0 0;
	}
}

.envoyer {
	position: relative;
	border: none;
	float: right;
	width: 49%;
	height: 48px;
	background-color: $color-3;
	
	text-transform: uppercase;
	color: $white;
	@include font-size(14);
	font-weight: 800;
	letter-spacing: 2px;

	transition: background-color 0.2s ease-in-out;

	&:after {
		position: absolute;
		content: '';
		height: 24px;
   		width: 20px;
		right: -50px;
		background: url('#{$image-path}arrow.svg') no-repeat;

		transition: right 0.2s ease-in-out;
	}

	&:hover {
		background-color: darken($color-3,10%);

		&:after {
			right: 15px;
		}
	}
}

.thanks {
	text-align: center;
	@include font-size(24);
	color: $color-3;
	display: none;

	a {
		color: $color-1;
		text-decoration: underline;
	}
}

.legal {
	@include font-size(12);
	font-family: Arial;
	font-weight: 400; 
	color: grey;

	a {
		color: darken(grey,30%);
	}
}

/* BOUTON RADIO CUSTOM */

.switch-title {
  margin-bottom: 10px;
}

.switch-field input {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.switch-field label {
  float: left;
}

.switch-field label {
	display: inline-block;
	text-align: center;
	width: 10%;
	padding: 8px 0;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-width: 1px 1px 1px 0;
	background-color: $white;
	transition:  all 0.1s ease-in-out;
	text-align: center;

	@include mq($from: tablet ,$until: desktop) {
		padding: 6px 0;
	}  
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
  background-color: $color-3;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
  border-width: 1px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

/* 
 ######    ####  ########   ########   ########      ###     ########   
##    ##    ##   ##     ##  ##         ##     ##    ## ##    ##     ##  
##          ##   ##     ##  ##         ##     ##   ##   ##   ##     ##  
 ######     ##   ##     ##  ######     ########   ##     ##  ########   
      ##    ##   ##     ##  ##         ##     ##  #########  ##   ##    
##    ##    ##   ##     ##  ##         ##     ##  ##     ##  ##    ##   
 ######    ####  ########   ########   ########   ##     ##  ##     ##  
*/




/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/